.treeContainer {
    display: flex;
    /* Uncomment these for fish-eye zoom:
    justify-content: center;
    align-items: center; */

    --tree-spacing-x: 15px; /* the actual space is double this */
    --tree-spacing-y: 5px;
}

/* tree rendering based on the briliant solution at http://fractaledmind.com/articles/css-tree/ */

.tree {
    list-style: none;
    font-size: 30;
}

.tree,
.tree .treeElement {
    margin: 0;
    border-color: #F48C06;
}

.tree li.treeElement {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: var(--tree-spacing-y);
    padding-bottom: var(--tree-spacing-y);
    position: relative;
    padding-left: var(--tree-spacing-x);
}

.tree li.treeElement::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    border-top: 1px solid;
    border-color: inherit;
    width: var(--tree-spacing-x);
}

.tree li.treeElement::after {
    content: '';
    position: absolute;
    left: 0;
    border-left: 1px solid;
    border-color: inherit;
}

.tree li.treeElement:last-of-type:not(:only-of-type)::after {
    height: 50%;
    top: 0;
}

.tree li.treeElement:first-of-type:not(:only-of-type)::after {
    height: 50%;
    bottom: 0;
}

.tree li.treeElement:not(:first-of-type):not(:last-of-type)::after {
    height: 100%;
}

.tree ul.treeElement,
.tree ol.treeElement {
    padding-left: var(--tree-spacing-x);
    position: relative;
}

.tree ul.treeElement::before,
.tree ol.treeElement::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    border-top: 1px solid;
    border-color: inherit;
    width: var(--tree-spacing-x);
}

.tree>li.treeElement {
    padding-left: 0;
}

.tree>li.treeElement::before,
.tree>li.treeElement::after {
    display: none;
}

.addChildButton {
    text-align: left;
}

.addChildButton button {
    padding: 3px;
    opacity: .5;
    transition-property: 'opacity';
    transition-duration: 0.2s;
}

.addChildButton button:hover {
    padding: 3px;
    opacity: 1;
}