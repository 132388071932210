@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #03071E; */
    /* color: rgba(255, 255, 255, 0.8); */
}

h1 {
    color: #D00000;
}

h1 a {
    text-decoration-color: #D00000;
    color: #D00000;
}

h1 a:visited {
    color: #D00000;
}

h2 {
    color: #E85D04;
}

.dark-button {
    background: #9D0208;
    color: white;
}

.padded-button {
    padding: 2px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}