.node {
    display: flex;
    background-color: var(--valueColour, lightgray);
    /* max-width: 200px;
    min-width: 80px; */
    width: 200px;

    padding: 8px;
    border-radius: 4px;
    /* border: 2px solid rgb(179, 179, 179); */
    text-align: left;

    transition: all 0.2s ease-out;
    /* animation: scaleIn 0.5s; */
    transform-origin: left;

    /* zoom: calc( ( var(--focussedDepth) - (2 * 100vw) / var(--maxDepth) * var(--maxDepth) ) / 200 ); */
}

.nodeWrapper.isDragging .node,
.nodeWrapper.isDragging + ul .node {
    opacity: 0.4;
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    } to {
        transform: scale(1);
    }
}

.node input[type=checkbox] {
    margin: 2px;
    zoom: 2;
    filter: hue-rotate(280deg); /* this is green hue on Chrome - likely different in other browsers */
}

.nodeLabel {
    resize: none;
    margin: auto;
    margin-left: 5px;
    /* font-family: Arial, Helvetica, sans-serif; */
    background-color: transparent;
    border: 0 none;
    outline: none;
    overflow:hidden
}

.nodeLabel:focus {
    background-color: rgba(0, 0, 0, 0.123);
}

.valueIcon {
    font-size: large;
    height: fit-content;
    padding: 5px;
}

.emojiPicker {
    z-index: 10;
    position: absolute;
    left: 20px;
}

.hoverButton {
    display: none;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    padding: 1px;
}

.deleteNodeButton {
    composes: hoverButton;
    right: 5px;
}

.toggleValueButton {
    composes: hoverButton;
    right: 30px;
}

.nodeWrapper:hover .hoverButton {
    display: inline-block;
}

.nodeWrapper {
    position: relative;
    /* transition: all 0.2s ease-out; */
}