.menuDiv {
    /* width: 200px; */
    position: absolute;
    right: 20px;
    top: 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
}

.menuDiv label {
    color: grey;
    font-size: small;
}

.select {
    text-align: left;
}

.keyboardShortcutIcon {
    margin-right: 5px;
    margin-top: 7px;
    font-size: larger;
    cursor: help;
}