@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
body {
    margin: 0;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #03071E; */
    /* color: rgba(255, 255, 255, 0.8); */
}

h1 {
    color: #D00000;
}

h1 a {
    -webkit-text-decoration-color: #D00000;
            text-decoration-color: #D00000;
    color: #D00000;
}

h1 a:visited {
    color: #D00000;
}

h2 {
    color: #E85D04;
}

.dark-button {
    background: #9D0208;
    color: white;
}

.padded-button {
    padding: 2px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.App {
    text-align: center;
}

.display-none{
    display:none;
}
.Node_node__1fwl9 {
    display: flex;
    background-color: lightgray;
    background-color: var(--valueColour, lightgray);
    /* max-width: 200px;
    min-width: 80px; */
    width: 200px;

    padding: 8px;
    border-radius: 4px;
    /* border: 2px solid rgb(179, 179, 179); */
    text-align: left;

    transition: all 0.2s ease-out;
    /* animation: scaleIn 0.5s; */
    -webkit-transform-origin: left;
            transform-origin: left;

    /* zoom: calc( ( var(--focussedDepth) - (2 * 100vw) / var(--maxDepth) * var(--maxDepth) ) / 200 ); */
}

.Node_nodeWrapper__3wWny.Node_isDragging__1qANp .Node_node__1fwl9,
.Node_nodeWrapper__3wWny.Node_isDragging__1qANp + ul .Node_node__1fwl9 {
    opacity: 0.4;
}

@-webkit-keyframes Node_scaleIn__3980h {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
    } to {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes Node_scaleIn__3980h {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
    } to {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.Node_node__1fwl9 input[type=checkbox] {
    margin: 2px;
    zoom: 2;
    -webkit-filter: hue-rotate(280deg);
            filter: hue-rotate(280deg); /* this is green hue on Chrome - likely different in other browsers */
}

.Node_nodeLabel__1yTHS {
    resize: none;
    margin: auto;
    margin-left: 5px;
    /* font-family: Arial, Helvetica, sans-serif; */
    background-color: transparent;
    border: 0 none;
    outline: none;
    overflow:hidden
}

.Node_nodeLabel__1yTHS:focus {
    background-color: rgba(0, 0, 0, 0.123);
}

.Node_valueIcon__1bvAe {
    font-size: large;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 5px;
}

.Node_emojiPicker__33YEU {
    z-index: 10;
    position: absolute;
    left: 20px;
}

.Node_hoverButton__HIBrT {
    display: none;
    position: absolute;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    padding: 1px;
}

.Node_deleteNodeButton__GBe5b {
    right: 5px;
}

.Node_toggleValueButton__3OpXU {
    right: 30px;
}

.Node_nodeWrapper__3wWny:hover .Node_hoverButton__HIBrT {
    display: inline-block;
}

.Node_nodeWrapper__3wWny {
    position: relative;
    /* transition: all 0.2s ease-out; */
}
.Tree_treeContainer__1al1t {
    display: flex;
    /* Uncomment these for fish-eye zoom:
    justify-content: center;
    align-items: center; */

    --tree-spacing-x: 15px; /* the actual space is double this */
    --tree-spacing-y: 5px;
}

/* tree rendering based on the briliant solution at http://fractaledmind.com/articles/css-tree/ */

.Tree_tree__1_g1- {
    list-style: none;
    font-size: 30;
}

.Tree_tree__1_g1-,
.Tree_tree__1_g1- .Tree_treeElement__2O5vb {
    margin: 0;
    border-color: #F48C06;
}

.Tree_tree__1_g1- li.Tree_treeElement__2O5vb {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: var(--tree-spacing-y);
    padding-bottom: var(--tree-spacing-y);
    position: relative;
    padding-left: var(--tree-spacing-x);
}

.Tree_tree__1_g1- li.Tree_treeElement__2O5vb::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    border-top: 1px solid;
    border-color: inherit;
    width: var(--tree-spacing-x);
}

.Tree_tree__1_g1- li.Tree_treeElement__2O5vb::after {
    content: '';
    position: absolute;
    left: 0;
    border-left: 1px solid;
    border-color: inherit;
}

.Tree_tree__1_g1- li.Tree_treeElement__2O5vb:last-of-type:not(:only-of-type)::after {
    height: 50%;
    top: 0;
}

.Tree_tree__1_g1- li.Tree_treeElement__2O5vb:first-of-type:not(:only-of-type)::after {
    height: 50%;
    bottom: 0;
}

.Tree_tree__1_g1- li.Tree_treeElement__2O5vb:not(:first-of-type):not(:last-of-type)::after {
    height: 100%;
}

.Tree_tree__1_g1- ul.Tree_treeElement__2O5vb,
.Tree_tree__1_g1- ol.Tree_treeElement__2O5vb {
    padding-left: var(--tree-spacing-x);
    position: relative;
}

.Tree_tree__1_g1- ul.Tree_treeElement__2O5vb::before,
.Tree_tree__1_g1- ol.Tree_treeElement__2O5vb::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    border-top: 1px solid;
    border-color: inherit;
    width: var(--tree-spacing-x);
}

.Tree_tree__1_g1->li.Tree_treeElement__2O5vb {
    padding-left: 0;
}

.Tree_tree__1_g1->li.Tree_treeElement__2O5vb::before,
.Tree_tree__1_g1->li.Tree_treeElement__2O5vb::after {
    display: none;
}

.Tree_addChildButton__2req1 {
    text-align: left;
}

.Tree_addChildButton__2req1 button {
    padding: 3px;
    opacity: .5;
    transition-property: 'opacity';
    transition-duration: 0.2s;
}

.Tree_addChildButton__2req1 button:hover {
    padding: 3px;
    opacity: 1;
}
.Menu_menuDiv__2ErfY {
    /* width: 200px; */
    position: absolute;
    right: 20px;
    top: 10px;
    text-align: left;
    display: flex;
    flex-direction: row;
}

.Menu_menuDiv__2ErfY label {
    color: grey;
    font-size: small;
}

.Menu_select__JfwYL {
    text-align: left;
}

.Menu_keyboardShortcutIcon__1Xzcu {
    margin-right: 5px;
    margin-top: 7px;
    font-size: larger;
    cursor: help;
}
.homepage_content__3CkRG {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.homepage_homepageButtonPrimary__3D9Xn {
    font-size: 200%;
    margin-left: auto;
    margin-right: auto;
}

.homepage_homepageButtonSecondary__1jQmP {
    background: #6A040F !important;
    margin-top: 10px;
}
